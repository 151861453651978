<template>
  <section class="dashboard">
    <div class="grid-margin">
      <b-alert
        :show="insufficientDefaultWorkspaceBalance"
        variant="danger"
        class="d-md-flex text-center justify-content-md-between"
      >
        <span>
          <i class="fa fa-warning mr-md-2" />
          {{
            this.userRole === "admin"
              ? "A workspace principal está quase sem saldo!"
              : "Devido à problemas internos, não é possível realizar saques no momento"
          }}
        </span>
        <b-button
          v-if="userRole === 'admin'"
          variant="link"
          class="p-md-0 pb-0 alert-link"
          @click="goToDefaultWorkspace"
        >
          Ver mais
        </b-button>
      </b-alert>
      <b-alert
        :show="!hasBankData"
        variant="danger"
        class="d-md-flex text-center justify-content-md-between"
      >
        <span>
          <i class="fa fa-warning mr-md-2" />
          Você precisa preencher seus dados bancários.
        </span>
        <b-button
          variant="link"
          class="p-md-0 pb-0 alert-link"
          @click="goToProfile"
        >
          Ir ao perfil
        </b-button>
      </b-alert>
      <b-alert
        :show="hasBankData && !isBankDataConfirmed"
        variant="danger"
        class="d-md-flex text-center justify-content-md-between"
      >
        <span>
          <i class="fa fa-warning mr-md-2" />
          Seus dados bancários ainda não foram confirmados ou estão inválidos.
        </span>
        <b-button
          variant="link"
          class="p-md-0 pb-0 alert-link"
          @click="goToProfile"
        >
          Ir ao perfil
        </b-button>
      </b-alert>
      <div class="px-5">
        <transition name="slide-up" appear>
          <b-row class="mb-2">
            <b-col md="9" cols="5" class="p-0">
              <h4 class="font-weight-bold">PayLog</h4>
              <h4 class="font-weight-normal mb-0">Antes é BEM melhor!</h4>
            </b-col>
            <b-col
              md="2"
              cols="4"
              class="text-right d-flex justify-content-end my-2"
            >
              <PlgAccountBalance :showBalance="showMoneyInfoCheck" />
            </b-col>
            <b-col
              md="1"
              cols="3"
              class="text-right border-left px-0 px-md-auto"
            >
              <div>
                <b-button
                  @click="seeMoneyInfo()"
                  :class="eyeIcon"
                  class="mt-1 text-left"
                  variant="link"
                  size="sm"
                ></b-button>
              </div>
            </b-col>
          </b-row>
        </transition>
      </div>

      <div class="row px-4 mt-5 grid-margin text-center d-flex">
        <transition name="slide-left" appear mode="out-in">
          <!-- <b-button class="shadow-sm home-button" variant="light">
            <i class="mdi mdi-square-inc-cash" style="font-size: 2rem;"></i><br>
            Antecipar
          </b-button> -->

          <!-- <b-button @click="goToPayment" variant="light">
            <i class="mdi mdi-qrcode-scan mr-1"></i>
            Sacar via Pix
          </b-button> -->

          <b-button
            @click="goToWithdraw"
            :disabled="insufficientDefaultWorkspaceBalance"
            :title="
              insufficientDefaultWorkspaceBalance
                ? 'Não é possível realizar saques no momento'
                : 'Sacar via PIX'
            "
            variant="light"
          >
            <i class="mdi mdi-qrcode-scan mr-1"></i>
            Sacar
          </b-button>

          <!-- <b-button class="shadow-sm home-button" @click="goToReceive" variant="light">
            <i class="fa fa-qrcode" style="font-size: 2rem;"></i><br>
            Receber Pix
          </b-button> -->
        </transition>
      </div>
    </div>

    <!-- <div class="col-md-12 stretch-card grid-margin grid-margin-md-0">
      <div class="card data-icon-card-primary">
        <div class="card-body">
          <p class="card-title text-white">Consórcio</p>
          <div class="row">
            <div class="col-8 text-white">
              <h3>Motos</h3>
              <p class="text-white font-weight-light mb-0">
                Faça um consórcio de uma moto ou bibicleta elétrica e pague com
                coletas e entregas.
              </p>
            </div>
            <div class="col-4">
              <h1>
                <i class="mdi mdi-motorbike"></i>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <template v-if="payments.length">
      <transition name="slide-up" appear mode="out-in">
        <div>
          <div class="p-md-0 p-3">
            <h4>Pagamentos</h4>
            <span class="text-muted">
              Abaixo você pode ver os seus próximos pagamentos agendados. <br />
              Caso não queira esperar, você pode sempre antecipar! 🤑 🤩
            </span>
          </div>
          <b-row class="mt-4">
            <b-col
              cols="12"
              md="3"
              v-for="payment in payments"
              :key="payment.id"
            >
              <PlgPaymentCard
                class="m-2 text-center"
                :payment="payment"
                @paid="getPayments"
              />
            </b-col>
          </b-row>
        </div>
      </transition>
    </template>
    <hr />
    <template>
      <transition name="slide-up" appear mode="out-in">
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card border rounded">
              <div class="card-body">
                <plg-bank-statement
                  :seeData="showMoneyInfoCheck"
                  @putTotals="getTotals"
                >
                  <template v-slot:header>
                    <b-row>
                      <b-col cols="8" lg="10">
                        <p class="card-title mb-0">Extrato</p>
                      </b-col>
                      <b-col cols="4" lg="2">
                        <router-link
                          class="text-dark"
                          :to="{ name: 'bank_statement' }"
                        >
                          <b-button size="sm" variant="primary"
                            >Ver mais</b-button
                          >
                        </router-link>
                      </b-col>
                    </b-row>
                  </template>
                </plg-bank-statement>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>

    <template
      v-if="
        (!loader && this.totalIns && showMoneyInfoCheck) ||
        (!loader && this.totalOuts && showMoneyInfoCheck)
      "
    >
      <transition name="slide-up" appear mode="out-in">
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card border rounded position-relative">
              <div class="card-body">
                <p class="card-title">Saldo inteligente</p>

                <div class="row">
                  <div
                    class="col-md-12 col-xl-3 d-flex flex-column justify-content-center"
                  >
                    <div class="ml-xl-4">
                      <h3 class="font-weight-light mb-xl-4">Quanto?</h3>
                      <p class="text-muted mb-2 mb-xl-0">
                        Descreve como você usa o aplicativo
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-9">
                    <div class="row">
                      <div class="col-md-6 mt-3 aligner-wrapper">
                        <div class="absolute absolute-center text-center">
                          <h1 class="mt-lg-2 mt-4">%</h1>
                        </div>
                        <div class="mb-lg-5">
                          <dougnutNorth
                            :height="192"
                            :ins="parseFloat(totalIns)"
                            :outs="parseFloat(totalOuts)"
                            @totalPercentages="calcPercentages"
                          ></dougnutNorth>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <table class="table table-borderless mt-5 report-table">
                          <tr>
                            <td class="text-muted">Entrou</td>
                            <td class="w-100 px-0">
                              <div class="progress progress-md mx-4">
                                <div
                                  class="progress-bar bg-success"
                                  role="progressbar"
                                  :style="{ width: insPercentage + '%' }"
                                  aria-valuenow="70"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                            <td>
                              <h5 class="font-weight-bold mb-0">
                                {{ Math.round(insPercentage) }}%
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-muted">Saiu</td>
                            <td class="w-100 px-0">
                              <div class="progress progress-md mx-4">
                                <div
                                  class="progress-bar bg-warning"
                                  role="progressbar"
                                  :style="{ width: outsPercentage + '%' }"
                                  aria-valuenow="30"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                            <td>
                              <h5 class="font-weight-bold mb-0">
                                {{ Math.round(outsPercentage) }}%
                              </h5>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-9" v-if="!showMoneyInfoCheck">
                    <div class="card border-0" style="text-align: center">
                      <i
                        class="mdi mdi-eye-off text-muted"
                        style="font-size: 50px"
                      ></i>
                      <h3 class="text-muted">Saldo Inteligente ocultado</h3>
                    </div>
                  </div>
                  <div
                    v-if="loader"
                    class="col-md-12 col-xl-9"
                    style="text-align: center"
                  >
                    <b-spinner></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </section>
</template>

<script>
import PlgAccountBalance from "../../components/accounts/PlgAccountBalance.vue";
import PlgBankStatement from "../../components/bank-statement/PlgBankStatement.vue";
import PlgPaymentCard from "../../components/payments/PlgPaymentCard.vue";
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue";
import PlgUsersApi from "../../apis/PlgUsersApi.vue";
import dougnutNorth from "../../components/charts/dashboard/dougnutNorth";
import moment from "moment";

export default {
  name: "dashboard",
  data() {
    return {
      loader: false,
      showMoneyInfoCheck: true,
      balance: 0,
      insufficientDefaultWorkspaceBalance: false,
      userRole: "",
      totalIns: null,
      totalOuts: null,
      user: {},
      insPercentage: null,
      outsPercentage: null,
      payments: [],
      tableFields: [],
      data: null,
      eyeIcon: "mdi mdi-eye-off mdi-36px",
    };
  },
  mixins: [PlgPaymentsApi, PlgUsersApi],
  computed: {
    hasBankData() {
      return (
        !!this.user.branch_code &&
        !!this.user.bank_code &&
        !!this.user.account_number
      );
    },
    isBankDataConfirmed() {
      return this.user.is_bank_data_confirmed;
    },
  },
  components: {
    dougnutNorth,
    PlgBankStatement,
    PlgAccountBalance,
    PlgPaymentCard,
  },
  methods: {
    goToProfile() {
      this.$router.push("/site/profile#bank-data");
    },
    goToDefaultWorkspace() {
      this.$router.replace({ name: "default_workspace" });
    },
    async getPayments() {
      let filter = this.getFilterForPaymentsApi();
      filter.receiver_id = localStorage.getItem("id");
      filter.after_date = moment().format("YYYY-MM-DD");
      filter.approved = ["waiting", "antecipation_requested"];
      const payload = {
        filter: filter,
      };
      this.payments = await this.getPaymentsApi(payload);
      this.payments = this.payments.data.payments;
    },
    goToReceive() {
      this.$router.replace({ name: "pix_generate_charge" });
    },
    goToWithdraw() {
      this.$router.replace({ name: "pix_withdraw" });
    },
    seeMoneyInfo() {
      if (!this.showMoneyInfoCheck) {
        this.showMoneyInfoCheck = true;
        this.eyeIcon = "mdi mdi-eye-off mdi-36px";
      } else if (this.showMoneyInfoCheck) {
        this.showMoneyInfoCheck = false;
        this.eyeIcon = "mdi mdi-eye mdi-36px";
      }
    },
    async getTotals(emit) {
      this.totalIns = emit["totalIns"];
      this.totalOuts = emit["totalOuts"];
    },
    async calcPercentages(emit) {
      this.insPercentage = emit["insPercent"];
      this.outsPercentage = emit["outsPercent"];
    },
  },
  async mounted() {
    this.insufficientDefaultWorkspaceBalance = JSON.parse(
      localStorage.getItem("insufficientDefaultWorkspaceBalance")
    );
    this.userRole = localStorage.getItem("role");
    const response = await this.getCurrentUser();
    this.user = response.data;
    await this.getPayments();
  },
};
</script>

<style scoped>
.item-card {
  border-radius: 30%;
}
.home-button {
  border-radius: 100px;
  height: 8em;
  width: 109.2px;
}
@media (max-width: 440px) {
  .item-card {
    width: 33%;
  }
}
</style>
